/**
 * Returns a formatted currency string
 */
import { PUBLIC_PAGES } from "@/constants/routes";
import router from "@/router";

export const formatCurrency = (price: number | bigint | string, currency = 'LEI', localeString = 'ro-RO', opts: any = {}) => {
  const options = {
    ...opts,
    style: 'currency',
    currency,
  };

  if (typeof price === 'string') {
    price = Number(price);
  }

  return new Intl.NumberFormat(localeString, options).format(price);
};

/**
 * Returns a formatted date
 */
export const formattedDate = (dateString: string, options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }, localeString = 'ro-RO') => Intl.DateTimeFormat(localeString, options).format(new Date(dateString));

/**
 * Returns a formatted time
 */
export const formattedTime = (timeString: string, options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }, localeString = 'ro-RO') => Intl.DateTimeFormat(localeString, options).format(new Date(timeString));

export const isPublicPage = () => PUBLIC_PAGES.includes(router.currentRoute.value.path) ;
