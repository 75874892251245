import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import {
  ROUTE_BOOKINGS,
  ROUTE_BOOKINGS_ADD,
  ROUTE_LOGIN,
  ROUTE_ROOMS,
  ROUTE_ROOMS_ADD,
  ROUTE_ROOMS_EDIT
} from "@/constants/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: ROUTE_BOOKINGS
  },
  {
    path: ROUTE_ROOMS,
    component: () => import ('../views/Rooms/Index.vue')
  },
  {
    path: ROUTE_ROOMS_ADD,
    component: () => import ('../views/Rooms/Add.vue')
  },
  {
    path: `${ROUTE_ROOMS_EDIT}/:roomId`,
    component: () => import ('../views/Rooms/Edit.vue')
  },
  {
    path: ROUTE_BOOKINGS,
    component: () => import ('../views/Bookings/Index.vue')
  },
  {
    path: ROUTE_BOOKINGS_ADD,
    component: () => import ('../views/Bookings/Add.vue')
  },
  {
    path: `${ROUTE_BOOKINGS_ADD}/:roomNumberIds/:startTime/:endTime`,
    component: () => import ('../views/Bookings/Add.vue')
  },
  {
    path: ROUTE_LOGIN,
    component: () => import ('../views/Auth/Login.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
