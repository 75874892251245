
// Dependecies
import { defineComponent } from 'vue';
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonButton,
  IonFooter,
} from "@ionic/vue";
import {mailOutline, mailSharp} from "ionicons/icons";

// Composables
import {useAuthentication} from "@/composables/useAuthentication";

// Constants
import {ROUTE_BOOKINGS, ROUTE_ROOMS} from "@/constants/routes";

export default defineComponent({
  name: "MainMenu",
  components: {
    IonContent,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonButton,
    IonFooter,
  },
  data() {
    return {
      appPages: [
        {
          title: this.$t('bookings.booking'),
          url: ROUTE_BOOKINGS,
          iosIcon: mailOutline,
          mdIcon: mailSharp
        },
        {
          title: this.$t('rooms.rooms'),
          url: ROUTE_ROOMS,
          iosIcon: mailOutline,
          mdIcon: mailSharp
        },
      ],
    }
  },
  setup() {
    const { user, logout } = useAuthentication();

    return {
      user,
      logout,
      mailOutline,
      mailSharp,
    }
  }
});
