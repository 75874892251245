import {computed, reactive, toRefs} from "vue";
import axios from "axios";
import {Preferences} from '@capacitor/preferences';
import {API_AUTH_LOGIN, API_AUTH_SOCIAL_LOGIN,  API_USER} from "@/constants/api";
import router from "@/router";
import {ROUTE_BOOKINGS, ROUTE_LOGIN} from "@/constants/routes";
import { Toast } from '@capacitor/toast';
import {Capacitor} from "@capacitor/core";
import {isPublicPage} from "@/utils/helpers";

export interface AuthData {
    token: string | null;
    user: object;
}

export interface Credentials {
    username: string;
    password: string;
}

const authData = reactive<AuthData>({
    token: null,
    user: {}
});

export function useAuthentication() {
    async function setUser(userData: any) {
        await Preferences.set({key: 'userToken', value: userData.token})
        authData.token = userData.token;
        authData.user = userData.user;
    }

    async function logout(): Promise<any> {
        await Preferences.remove({key: 'userToken'})
        authData.token = null;
        authData.user = {};
        axios.defaults.headers.common["Authorization"] = '';
        window.location.href = ROUTE_BOOKINGS;
    }

    function login(credentials: any) {
        axios.post(API_AUTH_LOGIN, {username: credentials.username, password: credentials.password})
            .then(({data: data}) => {
                finishLogin(data);
            })
    }

    function socialLogin(payload: any) {
        axios.post(API_AUTH_SOCIAL_LOGIN, payload)
            .then(async ({data: data}) => {
                finishLogin(data);
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    async function finishLogin(data: any) {
        if (data.status !== 'success'){
            if(Capacitor.isNativePlatform()){
                await Toast.show({ text: data.message, duration: 'long'});
            }else {
                alert(data.message);
            }
            return await logout();
        }

        axios.defaults.headers.common["Authorization"] = `Bearer ${data.data.token}`;
        await setUser(data.data);
        router.replace({path: ROUTE_BOOKINGS})
    }

    async function initUser(): Promise<any> {
        const {value: token} = await Preferences.get({key: 'userToken'});
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            axios.defaults.headers.common["Cache-Control"] = 'no-cache';
            axios.defaults.headers.common["Pragma"] = 'no-cache';
            axios.defaults.headers.common["Expires"] = '0';
            return axios.get(API_USER).then(async ({data: {data}}) => {
                await setUser(data);
                return true;
            }).catch(() => {
                axios.defaults.headers.common["Authorization"] = '';
                return router.replace({path: ROUTE_LOGIN})
            });
        } else {
            if(!isPublicPage()){
                return router.replace({path: ROUTE_LOGIN});
            }
        }
    }

    const isAuthenticated = computed((): boolean => !!authData.token && !!authData.user);

    return {
        login,
        socialLogin,
        logout,
        isAuthenticated,
        initUser,
        ...toRefs(authData)
    }
}