// Auth
export const ROUTE_LOGIN = '/login';

// Rooms
export const ROUTE_ROOMS = '/rooms';
export const ROUTE_ROOMS_ADD = '/rooms/add';
export const ROUTE_ROOMS_EDIT = '/rooms/edit';

// Bookings
export const ROUTE_BOOKINGS = '/bookings';
export const ROUTE_BOOKINGS_ADD = '/bookings/add';

export const PUBLIC_PAGES = [ROUTE_LOGIN]