export default {
    'common': {
        'welcome': 'Bine ati venit',
        'lastName': 'Nume',
        'firstName': 'Prenume',
        'phoneNumber': 'număr de telefon',
        'save': 'Salveaza',
        'add': 'Adauga',
        'yes': 'Da',
        'cancel': 'Anulare',
        'sure': 'Sigur',
        'logout': 'Deconectare',
        'comment': 'Informații suplimentare',
        'total': 'Total'
    },
    'rooms': {
        'rooms': 'Camere',
        'room': 'Cameră',
        'adults': 'Adultii',
        'children': 'Copii',
        'addRoom': 'Adauga camera',
        'selectRoom': 'Selecteaza cameră',
        'totalPersons': 'Persoane',
        'priceIncVAT': 'Pret cu TVA'
    },
    'bookings': {
        'addBooking': 'Rezervare',
        'booking': 'Rezervari',
        'from': 'Dela',
        'till': 'Pana',
        'room': 'Camera',
        'comment': 'Comment',
        'filters': {
            'filter': 'Filtru',
            'allReservations': 'Rezervări după dată',
            'freeRooms': 'Camere libere',
            'selectFilter': 'Selecteaza filtru'
        },
        'customer': 'Client'
    },
    'auth': {
        'username': 'E-mail',
        'password': 'Parola',
        'login': 'Autentificare',
        'authenticateToYourAccount': 'Autentifică-te la contul tau',
        'apple': 'Autentificare Apple',
        'google': 'Autentificare Google',
    },
    'alert': {
        'confirm': 'Sunteti sigur!',
        'deleteBooking': 'Vreti sa ştergeţi rezervare {lastName} {firstName} la camera {roomNumber} pe data de {bookingFrom} pana data de {bookingTill}!',
        'appUpdate': {
            'header': 'Actualizați aplicația!',
            'message': {
                'global': 'Actualizați aplicația',
                'ios': 'Actualizați aplicația în testflight',
                'android': '<a href="{link}">Descărcați aplicația</a>'
            }
        }
    },
}
