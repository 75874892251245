import { PushNotifications, Token } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import {Capacitor} from "@capacitor/core";
import axios from "axios";
import { API_PUSHTOKEN_ADD } from '@/constants/api';
import { useAuthentication } from '@/composables/useAuthentication';

const { isAuthenticated } = useAuthentication();

export function usePushNotifications() {
  async function handleRegistration(gToken: Token): Promise<void> {
    const { token } = await FCM.getToken();
    if (isAuthenticated.value && token) {
      /**
       * For some reason on Android we need to use the token that `@capacitor/push-notifications` provides
       * and on iOS the token that `@capacitor-community/fcm` provides. Hence the condition.
       */
      axios.post(API_PUSHTOKEN_ADD, {
        push_token: Capacitor.getPlatform() === 'ios' ? token : gToken.value,
      })
        .then(() => {
          // Do nothing!
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  }

  function handleRegistrationError(error: any): void {
    throw new Error(error);
  }

  // function handlePushNotificationActionPerformed(notification: ActionPerformed) {
  //   /**
  //    * Firebase console, reads `notification?.notification?.data?.link`
  //    * CURL: 'gcm.notification.url' on iOS, Android: `link` key in the notification payload
  //    */
  //   let url = notification?.notification?.data['gcm.notification.url'];
  //   if (notification?.notification?.data?.link) {
  //     url = notification?.notification?.data?.link;
  //   }
  //   if (url) {
  //     const slug = url.split('kiesmenu://').pop();
  //     if (slug) $router.push({ path: `/${slug}` });
  //   }
  // }

  function registerListeners(): void {
    PushNotifications.removeAllListeners(); // Make sure we remove any existing listeners to prevent unexpected behaviour.
    PushNotifications.addListener('registration', handleRegistration);
    PushNotifications.addListener('registrationError', handleRegistrationError);
    // PushNotifications.addListener('pushNotificationActionPerformed', handlePushNotificationActionPerformed);
  }

  async function registerPushNotifications() {
    if (Capacitor.isNativePlatform()) {
      registerListeners();
      const { receive: permissionStatus } = await PushNotifications.checkPermissions();
      if (permissionStatus === 'prompt' || permissionStatus === 'prompt-with-rationale') {
        await PushNotifications.requestPermissions();
      }
      if (permissionStatus !== 'denied') {
        await PushNotifications.register();
      }
    }
  }

  return {
    registerPushNotifications,
  };
}
