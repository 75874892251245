
import {IonApp, IonRouterOutlet, IonSplitPane} from '@ionic/vue';
import {defineComponent} from 'vue';

// Composables
import { usePushNotifications } from '@/composables/notifications/usePushNotifications';
import {useVersionManagement} from "@/composables/useVersionManagement";

// Components

import MainMenu from '@/components/MainMenu.vue';
// Constants

import {ROUTE_LOGIN} from '@/constants/routes';
// Utils
import { isPublicPage } from '@/utils/helpers';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    MainMenu,
  },
  setup() {
    const { registerPushNotifications } = usePushNotifications();
    const {intiVersionCheck} = useVersionManagement()

    return {
      intiVersionCheck,
      registerPushNotifications,
      isPublicPage,
      ROUTE_LOGIN,
    }
  },
  mounted() {
    this.intiVersionCheck();
    this.registerPushNotifications();
  },
});
