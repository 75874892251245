import {Capacitor} from '@capacitor/core';
import {App} from '@capacitor/app';
import {API_VERSIONMANAGEMENT} from '@/constants/api';
import axios from "axios";
import {BUILD_NUMBER} from "@/constants/info";
import {alertController} from "@ionic/vue";
import {useI18n} from "vue-i18n";

export function useVersionManagement() {
    const platform = Capacitor.getPlatform();
    const {t} = useI18n();

    function intiVersionCheck() {
        checkUpdate();

        App.addListener('appStateChange', ({isActive}) => {
            if (isActive) checkUpdate();
        });
    }

    function checkUpdate() {
        if (Capacitor.isNativePlatform()) {
            axios.get(`${API_VERSIONMANAGEMENT}?time=${(new Date().getTime())}`)
                .then(({
                           data: {
                               build
                           },
                       }) => {
                    if (BUILD_NUMBER < build) {
                        openMarketPage()
                    }
                });
        }
    }

    async function openMarketPage() {
        const header = t('alert.appUpdate.header');
        let message = 'alert.appUpdate.message.global';
        if (platform === 'ios') {
            message = t('alert.appUpdate.message.ios');
        } else if (platform === 'android') {
            message = t('alert.appUpdate.message.android', {'link': 'https://app.myhouse-booking.voina.nl/downloads/android/MyHouse-booking.apk'});
        }
        const alert = await alertController
            .create({
                header: header,
                message: message,
                backdropDismiss: false,
            });
        return alert.present();
    }

    return {
        intiVersionCheck,
    };
}
