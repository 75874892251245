export const DOMAIN_PREFIX = 'https://myhouse-booking.voina.nl';
// export const DOMAIN_PREFIX = 'http://myhouse-booking.test';

export const API_PREFIX = `${DOMAIN_PREFIX}/api/v1`;

// App
export const API_VERSIONMANAGEMENT = `${DOMAIN_PREFIX}/appversionmanagement.json`;

// Auth
export const API_AUTH_LOGIN = `${API_PREFIX}/auth/login`;
export const API_AUTH_SOCIAL_LOGIN = API_PREFIX + '/auth/social_login'

// Users
export const API_USER = `${API_PREFIX}/users`;

// Rooms
export const API_ROOMS = `${API_PREFIX}/rooms`;
export const API_ROOMS_VIEW = `${API_PREFIX}/rooms/view`;
export const API_ROOMS_ADD = `${API_PREFIX}/rooms/add`;
export const API_ROOMS_EDIT = `${API_PREFIX}/rooms/edit`;
export const API_ROOMS_FREE = `${API_PREFIX}/rooms/free`;

// Bookings
export const API_BOOKINGS = `${API_PREFIX}/bookings`;
export const API_BOOKINGS_ADD = `${API_PREFIX}/bookings/add`;
export const API_BOOKINGS_DELETE = `${API_PREFIX}/bookings/delete`;
export const API_BOOKINGS_COUNT_LAST_DATE = `${API_PREFIX}/bookings/count-and-last-date`;

// Push Notifications
export const API_PUSHTOKEN_ADD = `${API_PREFIX}/pushtoken/add`;
