import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import {useAuthentication} from "@/composables/useAuthentication";
import GoogleSignInPlugin from "vue3-google-signin"

import {IonicVue} from '@ionic/vue';

const {initUser} = useAuthentication();
import {createI18n} from "vue-i18n";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

// i18n
import ro from "@/locales/ro";
import {Capacitor} from "@capacitor/core";

const messages = {
    ro: ro,
}
const i18n = createI18n({
    legacy: false,
    locale: 'ro', // set locale
    fallbackLocale: 'ro', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(i18n);

if(!Capacitor.isNativePlatform()){
    app.use(GoogleSignInPlugin, {
        clientId: '155330294058-6utjinltnqarl8ijifls52elefba18qt.apps.googleusercontent.com',
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
    });
}

router.isReady().then(() => {
    initUser().then(() => {
        app.mount('#app');
    });
});
